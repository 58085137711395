// NOTE:
// Be careful of the useState that may require to be written in the Calendar.jsx
// Learn to make useState or method reusable as a parrameter
// Consider moving the methods into specific files for easier management
// e.g. Display file, Submit File, POST Request file, ...

import axios from "axios";
import AccessInformation from '../AccessInformation';
import dayjs from 'dayjs';
import { getUser } from '../AuthService';



const {
  requestConfig,
  getTasksUrl,
  getOneOffTaskDatesUrl,
  getTaskStatusUrl,
  calendarUrl
} = AccessInformation;

//GET TASKS
export const getTasks = async (dayOfWeek, recurrence_pattern, suburb) => {
return new Promise((resolve, reject) => {
    const requestBody = {
    dayOfWeek: dayOfWeek,
    recurrence_pattern: recurrence_pattern,
    suburb: suburb
    };

    axios
    .post(getTasksUrl, requestBody, requestConfig)
    .then((response) => {
        const task = response.data;
        resolve(task); // Resolve the promise with the data
        console.log("task: ");
        console.log(task)
    })
    .catch((error) => {
        console.error(error);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        const errorMessage = 'error.response.data.message';
        reject(errorMessage); // Reject the promise with an error message
        } else {
        const errorMessage = 'Sorry, backend server is down, please try again later!';
        reject(errorMessage); // Reject the promise with an error message
        }
    });
})
}


//GET ONE OFF TASKS
export const getOneOffTasks = async(date) => {
return new Promise((resolve, reject) => {
    const requestBody = {
    date: date
    };

    axios
    .post(getOneOffTaskDatesUrl, requestBody, requestConfig)
    .then((response) => {
        const task = response.data;
        resolve(task); // Resolve the promise with the data
        console.log("task: ");
        console.log(task)
    })
    .catch((error) => {
        console.error(error);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        const errorMessage = 'error.response.data.message';
        reject(errorMessage); // Reject the promise with an error message
        } else {
        const errorMessage = 'Sorry, backend server is down, please try again later!';
        reject(errorMessage); // Reject the promise with an error message
        }
    });
})
}




//GET HEADERS
export async function gettingHeaders(setCsvHeaders, jobControlTableName) {
    try {
        //console.log('gettigHeaders job table: ' + jobControlTableName)
        const requestBody = {
        dayOfWeek: "Tuesday",
        recurrence_pattern: "Fortnightly",
        suburb: jobControlTableName
        };

        const response = await axios.post(getTasksUrl, requestBody, requestConfig)
        const getData = await response.data.csvHeaders;
        setCsvHeaders(getData);
    } catch(error) {
            console.error(error);
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            console.error('error.response.data.message');
            } else {
            console.error('Sorry, backend server is down, please try again later!');
            }
        };
} 



//GET ONE OFF TASKS DATE
// export async function getOneOffTasksDate(tableName) {
//   const requestBody = {
//     tableName: tableName + "_One_Off",
//     partitionKeyName: "Date"
//   };
//   console.log(tableName + " oneoff table name")

//     try {
//       const response = await axios.post(getOneOffTaskDatesUrl, requestBody, requestConfig);
//       const data = response.data.message;
//       setOneOffDateList(data);

//     } catch(error) {
//       console.error(error);
//       if (error.response && (error.response.status === 401 || error.response.status === 403)) {
//         console.log('error.response.data.message');
//       } else {
//         console.log('Sorry, backend server is down, please try again later!');
//       }
//     }

// }



// GET TASK STATUS

// parameter date is the orginal parameter
// not sure if weeklyStatus will work as a let
export async function GetTaskStatus(date, taskStatus, stateSetters, otherParams) {
  //setters
  const {
    setWeeklyTaskAndStatus,
    setFortnightlyTaskAndStatus,
    setMonthlyTaskAndStatus,
    setQuarterlyTaskAndStatus,
    setIsTaskAndStatusReady
  } = stateSetters;

  //other params if needed in Calendar file
  const {
    // date,
    jobControlTableName,
  } = otherParams;

  let {
    weeklyStatus,
    fortnightlyStatus,
    monthlyStatus,
    quarterlyStatus} = taskStatus;  

  if(date && (jobControlTableName !== null || jobControlTableName || jobControlTableName !== '')) {
    try {
    const requestBody = {
    Date: date, //csvHeaderOrder,
    tableName: jobControlTableName
    };

    //console.log(csvHeaderOrder);
    if(date?.includes('Weekly')) {
    const response = await axios.post(getTaskStatusUrl, requestBody, requestConfig);
    const getData = await response.data;
    // console.log("GetTaskStatus function prints getData: ");
    // console.log(getData ? getData : "nothing")
    setWeeklyTaskAndStatus(getData);
    weeklyStatus = getData;
    //console.log('weekly:')
    //console.log(getData)
    setIsTaskAndStatusReady(true);

} else if(date?.includes('EvenFortnightly')) {
    const response = await axios.post(getTaskStatusUrl, requestBody, requestConfig);
    const getData = await response.data;
    setFortnightlyTaskAndStatus(getData);
    fortnightlyStatus = getData;
    //console.log('fortnightly:')
    //console.log(getData)
    setIsTaskAndStatusReady(true);

} else if(date?.includes('OddFortnightly')) {
    const response = await axios.post(getTaskStatusUrl, requestBody, requestConfig);
    const getData = await response.data;
    setFortnightlyTaskAndStatus(getData);
    fortnightlyStatus = getData;
    //console.log('fortnightly:')
    //console.log(getData)
    setIsTaskAndStatusReady(true);

} else if (date?.includes('Monthly')) {
    const response = await axios.post(getTaskStatusUrl, requestBody, requestConfig);
    const getData = await response.data;
    setMonthlyTaskAndStatus(getData)
    monthlyStatus = getData;
    //console.log('monthly')
    //console.log(getData)
    setIsTaskAndStatusReady(true);
} else if (date?.includes('Quarterly')) {
    const response = await axios.post(getTaskStatusUrl, requestBody, requestConfig);
    const getData = await response.data;
    setQuarterlyTaskAndStatus(getData)
    quarterlyStatus = getData;
    //console.log('quarterly')
    //console.log(getData)
    setIsTaskAndStatusReady(true);
}
} catch(error) {
    console.error(error);
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.error('error.response.data.message');
    } else {
        console.error('Sorry, backend server is down, please try again later!');
    }
    };
}
}



// GET TASKS FOR SELECTED DATE
export async function getTasksForSelectedDate(date, stateSetters, otherParams) {
    //setters
    const {
      setWeeklyTaskAndStatus,
      setFortnightlyTaskAndStatus,
      setMonthlyTaskAndStatus,
      setQuarterlyTaskAndStatus,
      setIsTaskAndStatusReady,
      setCsvHeaderOrder,
      setMonthlyTask,
      setMonthlyHeaderPosition,
      setQuarterlyTask,
      setQuarterlyHeaderPosition,
      setEvenFortnightlyTask,
      setEvenFortnightlyHeaderPosition,
      setOddFortnightlyTask,
      setOddFortnightlyHeaderPosition,
      setWeeklyTask,
      setWeeklyHeaderPosition,
      setOneOffTask,
      setOneOffHeaderPosition
    } = stateSetters;
  
    //other params if needed in Calendar file
    const {
      // date,
      selectedDate,
      csvHeaders,
      jobControlTableName,
      weeklyStatus,
      fortnightlyStatus,
      monthlyStatus,
      quarterlyStatus,
      oneOffStatus,
      oneOffDateList
    } = otherParams;  
    
    //status calls
    let taskStatus = {
      weeklyStatus,
      fortnightlyStatus,
      monthlyStatus,
      quarterlyStatus,
      oneOffStatus
    };
  
  //date is day of week
    console.log("selected date: ", selectedDate.format('YYYY-MM-DD')); // (currently printing twice)

    // recurrence set
    setWeeklyTaskAndStatus({});
    setMonthlyTaskAndStatus({});
    setFortnightlyTaskAndStatus({});
    setQuarterlyTaskAndStatus({});

    let weekNumber = 2;
    let taskBody;
    let task;
    const lastIndexHeader = csvHeaders.length - 1;
    const startingDate = csvHeaders[lastIndexHeader]?.split('"').join('');

    // checking for quarterlyStartingDate
    const regexSecondToLast = /^\d{4}-\d{2}-\d{2}$/;
    const secondToLastValid = regexSecondToLast.test(csvHeaders[csvHeaders.length-2]?.slice(1, -1));

    let quarterlyStartingDate;
    if (secondToLastValid) {
        quarterlyStartingDate = csvHeaders[csvHeaders.length-2];
    }
    const weekDifference = selectedDate.diff(startingDate, 'week');
    const monthDifference = selectedDate.diff(quarterlyStartingDate, 'month');

    console.log('quarterlyStartingDate: ', quarterlyStartingDate);
    console.log('selected day: ' + selectedDate);
    console.log('month difference: ', monthDifference);

    if(jobControlTableName !== null || jobControlTableName || jobControlTableName !== '') {
        
        if (csvHeaders.length > 0) {
    //calculate monthly (once 4 weeks)
            // for (let i = 14; i <= 20; i++) {
            for (let i = 0; i<= csvHeaders.length; i++) {
            if((csvHeaders[i]?.includes(date) && csvHeaders[i]?.includes('Monthly') && weekDifference % 4 === 0)) {
                console.log('monthly met');
                try {
                //setTaskAndStatus({});
                const statusDate = selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[i]
                setCsvHeaderOrder(date);
                taskBody = await getTasks(date, 'Monthly', jobControlTableName)
                task = taskBody.csvData.filter(item => item !== "");
                setMonthlyTask({ task });

                // calling the function GetTaskStatus from this file!
                //GetTaskStatus(statusDate);
                GetTaskStatus(statusDate, taskStatus, stateSetters, otherParams);
                setMonthlyHeaderPosition(i);
                console.log(task);
                break;
                } catch (error) {
                    console.error(error);
                }
            } else {
                setMonthlyTask(null);
                setCsvHeaderOrder(null);
                setMonthlyHeaderPosition(null);
            }
            }

    //calculate quarterly task (every 3 months)
            for (let i = 0; i<= csvHeaders.length; i++) {
                if((csvHeaders[i]?.includes(date) && csvHeaders[i]?.includes('Quarterly') && monthDifference % 3 === 0)) {
                console.log('quarterly met');
                try {
                    //setTaskAndStatus({});
                    const statusDate = selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[i]
                    setCsvHeaderOrder(date);
                    taskBody = await getTasks(date, 'Quarterly', jobControlTableName)
                    task = taskBody.csvData.filter(item => item !== "");
                    setQuarterlyTask({ task });

                    // calling the function GetTaskStatus from this file!
                    //GetTaskStatus(statusDate);
                    GetTaskStatus(statusDate, taskStatus, stateSetters, otherParams);
                    setQuarterlyHeaderPosition(i);
                    console.log(task);
                    break;
                } catch (error) {
                    console.error(error);
                }
                } else {
                setQuarterlyTask(null);
                setCsvHeaderOrder(null);
                setQuarterlyHeaderPosition(null);
                }
            }

    //calculate fortnightly (even)
            // for (let i = 7; i <=13; i++) {
            for (let i = 0; i <= csvHeaders.length; i++) {
            if ((csvHeaders[i]?.includes(date) && csvHeaders[i]?.includes('EvenFortnightly') && weekDifference % 2 === 0)) {
                
                try {
                //setTaskAndStatus({});
                const statusDate = selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[i]
                setCsvHeaderOrder(date);
                taskBody = await getTasks(date, 'EvenFortnightly', jobControlTableName)
                task = taskBody.csvData.filter(item => item !== "").map(item => item.replace(/�/g, ' ').trim());
                setEvenFortnightlyTask({ task }); 

                // calling the function GetTaskStatus from this file!
                //GetTaskStatus(statusDate);
                GetTaskStatus(statusDate, taskStatus, stateSetters, otherParams);
                setEvenFortnightlyHeaderPosition(i);
                
                console.log(task);
                console.log('even fortnightly met')
                } catch (error) {
                    console.error(error);
                }
                break;
            } else {
                setEvenFortnightlyTask(null);
                setCsvHeaderOrder(null);
                setEvenFortnightlyHeaderPosition(null);
            }
            }

    //calculate fortnightly (odd)
            // for (let i = 7; i <=13; i++) {
            for (let i = 0; i <= csvHeaders.length; i++) {
                if ((csvHeaders[i]?.includes(date) && csvHeaders[i]?.includes('OddFortnightly') && weekDifference % 2 !== 0)) {
                
                try {
                    //setTaskAndStatus({});
                    const statusDate = selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[i]
                    setCsvHeaderOrder(date);
                    taskBody = await getTasks(date, 'OddFortnightly', jobControlTableName)
                    task = taskBody.csvData.filter(item => item !== "").map(item => item.replace(/�/g, ' ').trim());
                    setOddFortnightlyTask({ task });

                    // calling the function GetTaskStatus from this file!
                    //GetTaskStatus(statusDate);
                    GetTaskStatus(statusDate, taskStatus, stateSetters, otherParams);
                    setOddFortnightlyHeaderPosition(i);
                    console.log(task);
                    console.log('odd fortnightly met')
                } catch (error) {
                    console.error(error);
                }
                break;
                } else {
                setOddFortnightlyTask([]);
                setCsvHeaderOrder(null)
                setOddFortnightlyHeaderPosition(null)
                }
            }

    //calculate weekly  
            // for(let i = 0; i <=6; i++) {
            for (let i = 0; i <= csvHeaders.length; i++) { 
            if((csvHeaders[i]?.includes(date) && csvHeaders[i]?.includes('Weekly'))) {
                    console.log('weekly met')
                    try {
                    //setTaskAndStatus({});
                    const statusDate = selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[i];
                    setCsvHeaderOrder(date);
                    taskBody = await getTasks(date, 'Weekly', jobControlTableName)
                    task = taskBody.csvData.filter(item => item !== "").map(item => item.replace(/�/g, ' ').trim());
                    setWeeklyTask({ task });

                    // calling the function GetTaskStatus from this file!
                    //GetTaskStatus(statusDate);
                    GetTaskStatus(statusDate, taskStatus, stateSetters, otherParams);
                    setWeeklyHeaderPosition(i);
                    // task = null;
                    console.log(task);
                    
                    } catch (error) {
                        console.error(error);
                    }
                    break;
                } else {
                    setWeeklyTask(null);
                    setCsvHeaderOrder(null);
                    setWeeklyHeaderPosition(null);
                    //setTaskAndStatus({});
                    // setFortnightlyTask(null);
                }
            }

            //calculate One Off Tasks
            console.log("before one off tasks check: ", oneOffDateList);
            console.log("before date list ", selectedDate.format('YYYY-MM-DD'))
            for (let i=0; i <= oneOffDateList.length; i++) {
            if(oneOffDateList[i]?.includes(selectedDate.format('YYYY-MM-DD'))) {
                console.log('One Off Job met');
                try {
                const statusDate = selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[i];
                setCsvHeaderOrder(date);
                taskBody = await getTasks(date, "OneOff", jobControlTableName);
                task = taskBody.csvData.filter(item => item !== "").map(item => item.replace(/�/g, ' ').trim());
                // not delared yet
                setOneOffTask({ task });
                GetTaskStatus(statusDate, taskStatus, stateSetters, otherParams);
                setOneOffHeaderPosition(i);
                // task = null;

                console.log(task);
                } catch (error) {
                console.error(error);
                }
            }
            }

        // Increment the week number after each Sunday
        if (date === 0) {
            weekNumber++;
        }
        }
    }
};




//SUBMIT SECTION
//This section need to be rewrite to be reusable
// use only 1 submit method for all
// pick parameters wisely
// parameters will be determined depending on WEEKLY, FORTNIGHTLY, MONTHLY, ... in Calendar.jsx

//SUBMIT ONE OFF TASKS
// export const submitOneOffTasks = () => {
//     const user = getUser();
//     const name = user !== 'undefined' && user ? user.name : '';

//     if (oneOffTask && isTaskAndStatusReady && (jobControlTableName !== null || jobControlTableName || jobControlTableName !== '')) {
//       const updatedTaskAndStatus = { ...oneOffTaskAndStatus };
  
//       oneOffTask.task.forEach((task, index) => {
//         const checkboxId = `task-${task}`;
//         const checkbox = document.getElementById(checkboxId);
        
//         if(checkbox) {
//           updatedTaskAndStatus[task] = checkbox ? checkbox.checked : false;
          
//         }
        
          

//       });
//   ///////
//       console.log(updatedTaskAndStatus);
//       console.log(jobControlTableName)
//       const requestBody = {
//         tableName: jobControlTableName,
//         Date: selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[oneOffHeaderPosition],//csvHeaderOrder,
//         name: name,
//         task: updatedTaskAndStatus
//       };
      
//       axios
//         .post(calendarUrl, requestBody, requestConfig)
//         .then((response) => {
//             const user = response.data;
//             console.log("tasks are done by " + user);
//         })
//         .catch((error) => {
//           console.error(error);
//           if (error.response && (error.response.status === 401 || error.response.status === 403)) {
//             const errorMessage = 'error.response.data.message';
//             console.log(errorMessage)
//           } else {
//             console.log('Sorry, backend server is down, please try again later!');
//           }
//         });
//     }
//     alert("tasks submitted")
//   }

  // SUBMIT WEEKLY TASKS
export const submitTasks = (task, taskAndStatus, headerPosition, otherParams) => {
  console.log("submitting tasks ...")
  console.log("task:", task);
  console.log("taskAndStatus:", taskAndStatus);
  console.log("headerPosition:", headerPosition);
  console.log("otherParams:", otherParams);
  const {
    isTaskAndStatusReady,
    jobControlTableName,
    selectedDate,
    csvHeaders
  } = otherParams;
    const user = getUser();
    const name = user !== 'undefined' && user ? user.name : '';

    if (task && isTaskAndStatusReady && (jobControlTableName !== null || jobControlTableName || jobControlTableName !== '')) {
      const updatedTaskAndStatus = { ...taskAndStatus };
  
      task.task.forEach((task, index) => {
        const checkboxId = `task-${task}`;
        const checkbox = document.getElementById(checkboxId);
        
        if(checkbox) {
          updatedTaskAndStatus[task] = checkbox ? checkbox.checked : false;     
        }
      });
  ///////
      console.log(updatedTaskAndStatus);
      console.log(jobControlTableName)
      const requestBody = {
        tableName: jobControlTableName,
        Date: selectedDate.format('YYYY-MM-DD') + '-' + csvHeaders[headerPosition],//csvHeaderOrder,
        name: name,
        task: updatedTaskAndStatus
      };
      
      axios
        .post(calendarUrl, requestBody, requestConfig)
        .then((response) => {
            const user = response.data;
            console.log("tasks are done by " + user);
        })
        .catch((error) => {
          console.error(error);
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            const errorMessage = 'error.response.data.message';
            console.log(errorMessage)
          } else {
            console.log('Sorry, backend server is down, please try again later!');
          }
        });
        alert("Tasks Submitted")
    } else {
      alert("Tasks Are Not Submitted")
    }
  };