import * as React from 'react';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Badge from '@mui/material/Badge';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { getUser } from '../AuthService';
import { Header } from '../../Header';
import '../CalendarStyle.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ColumTaskBar from '../../ColumnTaskBar';
import AccessInformation from '../AccessInformation';
import * as calendarUtils from './CalendarUtils';
import { setMonth } from 'date-fns';
import TaskListDisplay from './TaskListDisplay';

const { requestConfig, getTasksUrl, getOneOffTaskDatesUrl, getTaskStatusUrl, calendarUrl } = AccessInformation;


var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear);

const startingDate = '2023-09-25'
  
let weekDifference = 1
//console.log("iso week " + weekDifference)

//// info for obtaining data
const tuesday = 2; // 0 = Sunday, 1 = Monday, 2 = Tuesday, etc.

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}
let badgeType = '';

// default function of the calendar sample -> keep it in here
function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const daysInMonth = date.daysInMonth();
    const daysToHighlight = [];
    const weekDifferences = date.diff(startingDate, 'week');
    const jobControlTableName = localStorage.getItem('jobControlTableName');
    // console.log('fakeFetch year ' + date.format('YYYY') + "tableName " + jobControlTableName);

  // Loop through the days in the month and add the desired day of the week
  for (let day = 1; day <= daysInMonth; day++) {
    const dayOfWeek = dayjs(date).set('date', day).day();

    if (dayOfWeek === tuesday && weekDifference % 2 === 0) {
      daysToHighlight.push(day);
      const currentMonth = date.format('MMMM');
      // console.log('current month is ' + currentMonth )
      badgeType = 'moon'
    }

    if (dayOfWeek === 4)
    {
      daysToHighlight.push(day);
      badgeType = 'sun';
    }
    if (dayOfWeek === tuesday) {
      weekDifference++;
    }
    //console.log("WEEK DIFF FAKE " +  weekDifference)
  }
  
    // Simulate the fetch operation
    const timeout = setTimeout(() => {
      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException('aborted', 'AbortError'));
    };
  });
}
  

// THIS MAY NEED TO MOVE TO FUNCTION FILE
//getting tasks from s3 bucket (no status)
// requesting only, no action
// REPLACEMENT
// getTasks and getOneOffTasks functions were here

const initialValue = dayjs();
//2022-04-17 '2023-11-07'
// default function of calendar sample
function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, badgeType = [], ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
  //console.log('THE KEY IS: ' + props.day.toString())
    let badgeContent = '';
    const badgeTest = isSelected ? '🌚' : undefined;
    switch (badgeType) {
      case 'moon':
        badgeContent = isSelected ? '🌚' : undefined;
        break;
      case 'sun':
        badgeContent = isSelected ? '☀️' : undefined;
        break;
      case 'star':
        badgeContent = isSelected ? '⭐️' : undefined;
        break;
      default:
        badgeContent = undefined;
    }
    return (
      <div>
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={badgeContent}
      >
        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </Badge>

      </div>

    );
}

// default function of calendar sample
export default function DateCalendarServerRequest() {
  const location = useLocation();
  const currentPathname = location.pathname;
  //console.log("current path: " + currentPathname)
  const isCalendarRoute = location.pathname === '/calendar';
  //console.log('useLocation')

  const [csvHeaders, setCsvHeaders] = useState([]);
  const [oneOffDateList, setOneOffDateList] = useState([]);
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState();
  const jobControlTableName = localStorage.getItem('TableName');

  const [selectedDate, setSelectedDate] = useState(dayjs());

  // recurrence set
  const [weeklyHeaderPosition, setWeeklyHeaderPosition] = useState();
  const [evenFortnightlyHeaderPosition, setEvenFortnightlyHeaderPosition] = useState();
  const [oddFortnightlyHeaderPosition, setOddFortnightlyHeaderPosition] = useState();
  const [monthlyHeaderPosition, setMonthlyHeaderPosition] = useState();
  const [quarterlyHeaderPosition, setQuarterlyHeaderPosition] = useState();
  const [oneOffHeaderPosition, setOneOffHeaderPosition] = useState();

  //NEW MOVED
  // recurrence set
  const [taskAndStatus, setTaskAndStatus] = useState({});
  const [weeklyTaskAndStatus, setWeeklyTaskAndStatus] = useState({});
  const [fortnightlyTaskAndStatus, setFortnightlyTaskAndStatus] = useState({});
  const [monthlyTaskAndStatus, setMonthlyTaskAndStatus] = useState({});
  const [quarterlyTaskAndStatus, setQuarterlyTaskAndStatus] = useState({});
  const [oneOffTaskAndStatus, setOneOffTaskAndStatus] = useState({});

  const [isTaskAndStatusReady, setIsTaskAndStatusReady] = useState(false);
  
  // recurrence set
  let weeklyStatus = {};
  let fortnightlyStatus = {};
  let monthlyStatus = {};
  let quarterlyStatus = {};
  let oneOffStatus = {};


  const [csvHeaderOrder, setCsvHeaderOrder] = useState('');
  // recurrence set
  const [weeklyTask, setWeeklyTask] = useState(null);
  const [evenFortnightlyTask, setEvenFortnightlyTask] = useState([]);
  const [oddFortnightlyTask, setOddFortnightlyTask] = useState([]);
  const [monthlyTask, setMonthlyTask] = useState([]);
  const [quarterlyTask, setQuarterlyTask] = useState([]);
  const [oneOffTask, setOneOffTask] = useState([]);
  // Parameters used to call methods and functions in CalendarUtils
  // These will be used for all methods and functions calls

  //state setter: for useState setters
  const stateSetters = {
    setCsvHeaders,
    setOneOffDateList,
    // setIsLoading,
    setSelectedDate,
    setWeeklyHeaderPosition,
    setEvenFortnightlyHeaderPosition,
    setOddFortnightlyHeaderPosition,
    setMonthlyHeaderPosition,
    setQuarterlyHeaderPosition,
    setOneOffHeaderPosition,
    setTaskAndStatus,
    setWeeklyTaskAndStatus,
    setFortnightlyTaskAndStatus,
    setMonthlyTaskAndStatus,
    setQuarterlyTaskAndStatus,
    setOneOffTaskAndStatus,
    setIsTaskAndStatusReady,
    setWeeklyTask,
    setEvenFortnightlyTask,
    setOddFortnightlyTask,
    setMonthlyTask,
    setQuarterlyTask,
    setOneOffTask,
    setCsvHeaderOrder
  };

  // otherParams: just an object for parameters needed
  const otherParams = {
    //date, // must be specified
    selectedDate,
    jobControlTableName,
    weeklyStatus,
    fortnightlyStatus,
    monthlyStatus,
    quarterlyStatus,
    oneOffStatus,
    csvHeaders,
    oneOffDateList,
    isTaskAndStatusReady
  }

  // console.log("job control ", jobControlTableName)

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setIsLoading(false);
      })
      .catch((error) => {
        // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }

    setIsLoading(true);
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(selectedDate)
  };

  //REPLACEMENT: gettingHeaders() used to be here

  // THIS MAY NEED TO MOVE TO FUNCTION FILE
  //get one off date (similar to gettingHeader)
  // getOneOffTaskDate function used to be here
  // REPLACEMENT

  useEffect(() => {
    if(jobControlTableName) {
      console.log('jobControlTableName mounted')
      // REPLACEMENT
      // gettingHeaders();
      calendarUtils.gettingHeaders(setCsvHeaders,jobControlTableName);
      // getOneOffTasksDate(jobControlTableName);
    }
  
}, []);

useEffect(() => {
  console.log("one off date: ", oneOffDateList);

  console.log('csvHeaders:', csvHeaders);
}, [csvHeaders]);
  //-------------

//recurrence sets used to be here

  // THIS MAY NEED TO MOVE TO FUNCTION FILE
  // recurrence set
  // REPLACEMENT: GetTaskStatus(date) used to be here

  // REPLACEMENT: getTasksForSelectedDate(date) used to be here


  useEffect(() => {
    // REPLACEMENT
    // getTasksForSelectedDate(selectedDate?.format('dddd'));
    calendarUtils.getTasksForSelectedDate(selectedDate?.format('dddd'), stateSetters, otherParams)
  }, [selectedDate, csvHeaders]);

  // React.useEffect(() => {
  //   GetTaskStatus().then(() => {
  //     setIsTaskAndStatusReady(true);
  //   })
  //   console.log('task and status');
  //   //console.log(taskAndStatus);

  // }, [csvHeaderOrder])


  //submit one off tasks used to be here
  // REPLACEMENT

  //REPLACEMENT
  // Display elements used to be here, see example at the end of TaskListDisplay.jsx


  const [expand, setExpand] = useState(false);

  return (
    // <div>
    //   {street === '' ? (
    //     <div>
    //     <h1>Please Clock in first to get the calendar Or your site does not have a calendar</h1>
    //     </div>
    //   ) : (
      isCalendarRoute && (
        <LocalizationProvider className='calendar-container-parent' dateAdapter={AdapterDayjs} >
          
        <Header title="Calendar" />
        <ColumTaskBar columnDisplay='none' />
        <div className='calendar-container-parent'>
          <div className='DateCalendar' >
            <DateCalendar
              defaultValue={initialValue}
              loading={isLoading}
              value={selectedDate}
              onMonthChange={handleMonthChange}
              onChange={handleDateChange}
              renderLoading={() => <DayCalendarSkeleton />}
              // slots={{
              //   day: ServerDay,
              // }}
              style={{
                boxShadow: "5px 5px 20px rgba(0.5, 0.5, 0.5, 0.5)",    
              }}
              slotProps={{
                day: {
                  highlightedDays,
                },
              }}
            />
          </div>
          
          <div className={`task-field ${expand ? 'active' : 'inactive'}`} >
                  <section className='task-header' >
                    <button id='expand-button' onClick={() => setExpand(!expand)}>
                      {expand ? 'Collapse' : 'Expand'}
                    </button>
                  </section>
                  <h4>{jobControlTableName} <br />Work Schedule:</h4>
                <p>{selectedDate.format('dddd')}</p>
                  <ul>
                    <TaskListDisplay
                      tasks={evenFortnightlyTask?.task || oddFortnightlyTask?.task}
                      taskAndStatus={fortnightlyTaskAndStatus}
                      setTaskAndStatus={setFortnightlyTaskAndStatus}
                      taskType={"Fortnightly"}
                      submitTask={evenFortnightlyTask || oddFortnightlyTask}
                      headerPosition={evenFortnightlyHeaderPosition ?? oddFortnightlyHeaderPosition}
                      otherParams={otherParams}
                      />

                    <TaskListDisplay
                      tasks={monthlyTask?.task}
                      taskAndStatus={monthlyTaskAndStatus}
                      setTaskAndStatus={setMonthlyTaskAndStatus}
                      taskType={"Monthly"}
                      submitTask={monthlyTask}
                      headerPosition={monthlyHeaderPosition}
                      otherParams={otherParams}
                      />
                      
                    <TaskListDisplay
                      tasks={quarterlyTask?.task}
                      taskAndStatus={quarterlyTaskAndStatus}
                      setTaskAndStatus={setQuarterlyTaskAndStatus}
                      taskType={"Quarterly"}
                      submitTask={quarterlyTask}
                      headerPosition={quarterlyHeaderPosition}
                      otherParams={otherParams}
                      />

                    <TaskListDisplay
                      tasks={weeklyTask?.task}
                      taskAndStatus={weeklyTaskAndStatus}
                      setTaskAndStatus={setWeeklyTaskAndStatus}
                      taskType={"Weekly"}
                      submitTask={weeklyTask}
                      headerPosition={weeklyHeaderPosition}
                      otherParams={otherParams}
                      />
                  </ul>
            </div>  
          </div>
          <div className='background'></div>
      </LocalizationProvider>
      )
  );
}